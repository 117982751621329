import { ConfirmDialogComponent } from './modules/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SwUpdate, SwPush, VersionReadyEvent } from '@angular/service-worker';
import * as moment from 'moment';
import 'moment/min/locales';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { LayoutService } from 'src/app/services/layout.service';
import { Member } from './interfaces/member.interface';
import { AuthService } from './services/auth.service';
import { MembersService } from './services/members.service';
import { environment } from './../environments/environment';
import { InformationService } from "./services/information.service";
import { ConnectionService } from './services/connection.service';
import { UserinfoService } from './services/userinfo.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { merge, Observable, of, iif, BehaviorSubject, Subscription } from 'rxjs';
import * as EasyMDE from 'easymde';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
type Button = ButtonWithPath | ButtonWithChildren;

interface ButtonWithPath {
  label: string;
  path: string;
  right?: string;
  external: boolean;
  visible?: Observable<boolean>;
}

interface ButtonWithChildren {
  label: string;
  children: Button[];
  right?: string;
  visible?: Observable<boolean>;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showOfflineNotice$ = of(false);

  showHeader$ = of(true);
  fullWidth$ = of(false);

  showBackdrop$: BehaviorSubject<boolean>;
  showBlack$: BehaviorSubject<boolean>;
  me: Member;

  user$: Observable<Member>;
  showAdmin$: Observable<boolean>;
  showBlack = false;

  buttons : Button[] = [
    {
      path: "/members",
      label: "BBB",
      right: "GetMembers",
      external: false
    },
    {
      label: "Vereniging",
      children: [
        {
          path: "/groups/type/bestuur",
          label: "Bestuur",
          external: false
        },
        {
          path: "/groups/type/commissie",
          label: "Commissies",
          external: false
        },
        {
          path: "/groups/type/competitie",
          label: "Competitieploegen",
          external: false
        },
        {
          path: "/groups/type/wedstrijd",
          label: "Wedstrijdploegen",
          external: false
        },
        {
          path: "/groups/type/dispuut",
          label: "Disputen",
          external: false
        },
        {
          path: "/groups/type/genootschap",
          label: "Genootschappen",
          external: false
        },
        {
          path: "/reservables/overview",
          label: "De vloot",
          external: false
        },
        {
          path: "/albums",
          label: "Fotoalbums",
          right: null,
          external: false
        },
      ],
      right: "GetGroups",
    },

    {
      path: "/reservables",
      label: "Afschrijven",
      right: "GetReservables",
      external: false
    },
    {
      path: "/events",
      label: "Kalender",
      right: "GetEvents",
      external: false
    },
    {
      path: "/articles",
      label: "Forca",
      right: "GetArticles",
      external: false
    },
    {
      label: "Uitslagen",
      right: "GetMatches",
      children: [
        {
          label: "Wedstrijden",
          path: "/matches",
          external: false
        },
        {
          label: "Blikkenlijst",
          path: "/matches/rankings",
          external: false
        },
        {
          label: "Records",
          path: "/records",
          external: false
        },
      ],
    },
    {
      label: "Informatie",
      right: null,
      children: []
    },
    {
      path: "/coupons",
      label: "Orca deals",
      right: null,
      external: false
    },
    {
      label: "Sites",
      right: null,
      children: [
        {
          label: "A.U.S.R. Orca",
          path: "https://orcaroeien.nl/",
          external: true
        },
        {
          label: "Blik Op Roeien",
          path: "https://blikoproeien.nl/",
          external: true
        },
        {
          label: "Competitie Slotwedstrijden",
          path: "https://composlot.orcaroeien.nl/",
          external: true
        },
        {
          label: "Orca-wiki",
          path: "https://orcawiki.nl/",
          external: true
        },
        {
          label: "Oud-Orca",
          path: "http://www.oud-orca.nl/",
          external: true
        },
        {
          label: "Teambuilding Orca",
          path: "https://roeieninutrecht.nl/",
          external: true
        },
        {
          label: "Veilig Orca",
          path: "https://veiligorca.orcaroeien.nl",
          external: true
        },
      ]
    }
  ]

  constructor(
    private connectionService: ConnectionService,
    private updates: SwUpdate,
    private swPush: SwPush,
    @Inject(DOCUMENT) private document: any,
    private layoutService: LayoutService,
    public authService: AuthService,
    private userInfoService: UserinfoService,
    private informationService: InformationService,
    private memberService: MembersService,
    public loader: LoadingBarService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    //This is for the markdown editor temporary fix
    window.EasyMDE = EasyMDE;

    this.showHeader$ = this.layoutService.headerVisibility;

    this.fullWidth$ = this.layoutService.fullWidth;

    moment.locale("nl-nl");
    this.showOfflineNotice$ = this.connectionService.isOnline().pipe(map(x => !x))

    if (environment.production) {
      // Check for Service Worker updates
      setTimeout(() => {
        if (environment.production) {
          this.updates.checkForUpdate();
        }
      }, 1);

      const updatesAvailable = this.updates.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        })));
      updatesAvailable.subscribe((event) => {
        this.updates
          .activateUpdate()
          .then(() => this.document.location.reload());
      });
    }

  }
  ngOnInit(): void {
    this.showBackdrop$ = this.layoutService.backdropVisibility;
    this.showBlack$ = this.layoutService.blackBG;
    
    const getMemberObs = this.userInfoService.getUserID().pipe(
      switchMap((memberID) => this.memberService.getMember(memberID)),
      tap(() => {
        this.getButtons();
        this.getDynamicNavBar();
      })
    );
    this.user$ = this.authService.userLoggedIn.pipe(
      switchMap((result) => iif(() => result, getMemberObs, of(null)))
    );
    this.showAdmin$ = this.authService.userLoggedIn.pipe(
      filter((change) => change),
      switchMap(() => of(this.authService.hasRole(["Admin", "Bestuur"])))
    );
  }

  // THIS SECTION ADDS THE JOIN WEBCIE BUTTON
  move(e, btn) {
    
    // e = e || window.event;
    btn.style.position = "absolute";
    btn.style.left = (e.clientX - 30) + "px";
    btn.style.top = (e.clientY - 15) + "px";

    btn.innerHTML = "Join de WebCie";

  }

  leave(e, btn){
    btn.style.removeProperty('position');
    btn.style.removeProperty('left');
    btn.style.removeProperty('top');

    // this.jumpyButton.style.left = '100px';
    // this.jumpyButton.style.top = '0';
  }

  joinWebCie(btn) {
      const modalRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
              title: 'Neem contact op!',
              body: `Leuk dat je overweegt bij de WebCie te gaan ;)! Neem contact op met Rob (06 45448889), zodat je meteen bij de volgende vergadering kan zijn!`,
          },
      });
  }
  // END OF JOIN WEBCIE BUTTON


  sub: Subscription; // zodat we later kunnen unsubben

  getDynamicNavBar() {

    // Mapping the information pages to buttons
    let informationPageButtonChildren$ = this.informationService.getInformationNavBar()
      .pipe(map((pages) => {
        let informationPageButtonChildren : ButtonWithPath[] = pages.map((page) => 
          ({
            label: page.title,
            path: "informationpage/" + page.title,
            external: false, 
            })
      )

      // Adding the "add new informationpage" button
      informationPageButtonChildren.push(
              {
                label: "Voeg informatiepagina toe",
                path: "informationpage/new",
                external: false,
                right: "EditInformationPage"
              });
        return informationPageButtonChildren;
      }))

      // Hier is ook de optie om meerdere dynamische componenten te mergen met merge() zodat je maar één keer getButtons() hoeft aan te roepen. 

      // subscribing to the (observable)buttons and adding them to the buttons
      this.sub = informationPageButtonChildren$.subscribe((informationPageButtonChildren) => {
        (this.buttons.find(x => x.label === "Informatie") as ButtonWithChildren).children = informationPageButtonChildren;
        this.getButtons()
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  // Deze functie checkt of bepaalde rechten hebt
  async getButtons() {
    this.buttons.forEach((button) => {

      // We bekijken voor knoppen met subknoppen of je ieder van die subknoppen mag bekijken
      if ((button as ButtonWithChildren).children) {
        (button as ButtonWithChildren).children.forEach((child) => {
          // tenzij je het recht hebt mag je de knop niet zien, de standaard is dat je de knop niet mag zien

          if (child.right) {
            const childRight$ = this.userInfoService.hasRight(child.right);
            child.visible = childRight$;
          }
          else{
            child.visible = of(true);
          }

        });
      }
      if (button.right) {
        const buttonRight$ = this.userInfoService.hasRight(button.right);
        button['visible'] = buttonRight$;
      }
      else {
        button["visible"] = of(true);
      }
    });
  }
}
